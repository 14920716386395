<template>
  <!-- 应用主页 -->
  <div class="index_wrapper" ref="indexWrapper">
    <div class="titleBar" v-if="isApp" ref="titleBar"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="isShowAccountView = true">
        <span class="icon-more"></span>
      </div>
      <div class="title_txt">
        <span>智即停运营管理</span>
      </div>
    </div>

    <div class="index-bottom">
      <div class="index_operation">
        <!-- 路边停车场区域 -->
        <div class="opeartion-outside">
          <div class="partition-title">
            <i class="icon-parkingIcon title-icon"></i>
            <span class="title-text">路边停车场</span>
          </div>

          <div
            class="index_operation_item"
            @click="$router.push({ name: 'Charge' })"
          >
            <div class="item_title">收费情况</div>
            <div class="item_content">
              查看辖区内的车场、岗位及收费员的收费情况
            </div>
            <div class="background">
              <img src="../assets/images/charge.png" alt="" />
            </div>
          </div>
          <div
            class="index_operation_item"
            @click="$router.push({ name: 'Team' })"
          >
            <div class="item_title">班组信息</div>
            <div class="item_content">查看管理的收费岗信息及收费员考勤信息</div>
            <div class="background">
              <img src="../assets/images/team.png" alt="" />
            </div>
          </div>
          <div
            class="index_operation_item"
            @click="$router.push({ name: 'Scheduling' })"
          >
            <div class="item_title">排班管理</div>
            <div class="item_content">配置收费岗的班次、为收费员排班</div>
            <div class="background">
              <img src="../assets/images/scheduling.png" alt="" />
            </div>
          </div>
        </div>

        <!-- 场内停车场区域 -->
        <div class="partition-title">
          <i class="icon-parkingIcon title-icon"></i>
          <span class="title-text">场内停车场</span>
        </div>

        <div
          class="index_operation_item"
          @click="$router.push({ name: 'InsideCharge' })"
        >
          <div class="item_title">收费情况</div>
          <div class="item_content">
            查看辖区内各车场的收费情况
          </div>
          <div class="background">
            <img src="../assets/images/charge.png" alt="" />
          </div>
        </div>

        <div
          class="index_operation_item"
          @click="$router.push({ name: 'Coupons' })"
        >
          <div class="item_title">场内优惠券</div>
          <div class="item_content">
            查看辖区内各车场的优惠券
          </div>
          <div class="background">
            <img src="../assets/images/coupons.png" alt="" />
          </div>
        </div>
      </div>

      <!-- 主页智即停 logo -->
      <div class="index_icon">
        <img class="auto_img" src="../assets/images/登录页logo.png" alt="" />

        <div class="appCode" v-if="isApp">v{{ appCode }}</div>
      </div>
    </div>

    <!-- 侧栏弹出框 -->
    <transition tag="div" name="pop">
      <div
        class="index_popup_box"
        v-show="isShowAccountView"
        :style="{
          width: '50%',
          top: `${titleBar}px`,
          bottom: `${nativeBarHeight}px`,
          backgroundColor: '#01153a'
        }"
      >
        <div class="index_popup">
          <div class="index_account">
            <div class="account_pic">
              <img
                class="auto_img"
                :src="avatar || require('../assets/images/yonghu.png')"
                alt=""
              />
              <!-- <div class="account_message">
              1
            </div> -->
            </div>
            <div class="account_name">{{ username }}</div>
          </div>

          <div class="index_setting">
            <div class="settting_item">
              <div class="item_icon">
                <img class="auto_img" src="../assets/images/消息.png" alt="" />
              </div>
              <div
                class="item_title"
                @click="$router.push({ name: 'Message' })"
              >
                消息通知
              </div>
              <div class="item_msg" v-show="msgCount > 0">{{ msgCount }}</div>
            </div>

            <div class="settting_item" @click="dialVideoTalk" v-if="isApp">
              <div class="item_icon">
                <span class="icon-dial"></span>
              </div>
              <div class="item_title">
                呼叫指挥中心
              </div>
            </div>

            <div class="settting_item" @click="onUpdate(1)" v-if="isApp">
              <div class="item_icon update">
                <img class="auto_img" src="../assets/images/更新.png" alt="" />
              </div>
              <div class="item_title">
                检查更新
              </div>
              <div class="item_point" v-if="isRemindUpdate"></div>
            </div>

            <div
              class="settting_item"
              @click="$router.push({ name: 'EditPassword' })"
            >
              <div class="item_icon">
                <img class="auto_img" src="../assets/images/设置.png" alt="" />
              </div>
              <div class="item_title">
                设置
              </div>
            </div>
          </div>

          <div class="index_logout" @click="logout">
            <div class="logout">退出登录</div>
          </div>
        </div>
      </div>
    </transition>
    <div
      class="index_popup_mask"
      v-show="isShowAccountView"
      @click="isShowAccountView = false"
    ></div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { mapState } from "vuex";
export default {
  data: () => ({
    isShowAccountView: false, //显示弹出层
    msgCount: 0, //未读消息数量
    appCode: "", //应用对比码
    isRemindUpdate: false, //是否需要更新
    titleBar: 0 //顶部栏高度
  }),
  methods: {
    getContainer() {
      console.log("this.$refs.indexWrapper == >", this.$refs.indexWrapper);
      return this.$refs.indexWrapper;
    },

    /**
     * 登出操作
     */
    logout() {
      Dialog.confirm({
        title: "系统提示",
        message: "确认退出登录"
      }).then(() => {
        let tenantName = this.$db.get("_c", "");
        this.$jsBridge.logout();
        this.$db.clear();
        this.$router.replace({
          name: "Login",
          query: {
            tenantName
          }
        });
      });
    },

    /**
     * 更新App
     * type  0:系统自动更新  1：用户点击更新
     */
    onUpdate(type = 0) {
      //获取本机apk版本对比码
      this.$jsBridge.appVerCode(appVerCode => {
        //请求后台最新apk对比码
        // this.$get(this.$config.APK_MANAGE_UPDATE_CAPTAIN_VERSION)
        this.$api.updateCaptainVersion().then(res => {
          let data = res["data"]["data"];
          console.log(appVerCode, data["verCode"]);

          //后台对比码 大于 本机对比码 则提醒更新
          if (data["verCode"] > appVerCode) {
            this.isRemindUpdate = true;
            Dialog.confirm({
              title: "应用更新",
              message: `<div style="text-align : left ;">应用版本：${data["verStr"]}<br/><br/>更新内容：${data["details"]}<br/><br/>应用大小：${data["length"]}M</div>`
            })
              .then(() => {
                this.$jsBridge.updateApp(data["url"]);
              })
              .catch(() => {
                clearTimeout(this.updateTimer);
                this.updateTimer = setTimeout(() => {
                  this.onUpdate();
                }, 10000);
              });
          } else {
            //当主动点击时，进行提醒
            if (type == 1) {
              Dialog.alert({
                title: "系统提醒",
                message: "当前应用版本已是最新"
              });
            }
          }
        });
      });
    },

    /**
     * 发起视频通话
     */
    dialVideoTalk() {
      Dialog.confirm({
        title: "系统提示",
        message: "确认发起视频通话"
      }).then(() => {
        this.$jsBridge.callVideoTalk();
      });
    },

    /**
     * 请求获取未读消息数量
     */
    getMsgCount() {
      // this.$get(this.$config.TL_MESSAGE_GET_MESSAGE_COUNT)
      this.$api.getMessageCount().then(res => {
        this.msgCount = res["data"]["data"];
      });
    },

    /**
     * 监听Native更新未读消息数
     */
    onListenGetMsg() {
      console.log(this.isApp);
      if (this.isApp) {
        this.$jsBridge.updateMsgCount(() => {
          this.getMsgCount();
        });
      } else {
        clearInterval(this.getMsgTimer);
        this.getMsgTimer = setInterval(() => {
          this.getMsgCount();
        }, 10000);
      }
    },

    /**
     * 获取版本号
     */
    getAppVerName() {
      this.$jsBridge.appVerName(res => {
        this.appCode = res;
      });
    },

    getTitleBarHeight() {
      if (this.isApp) {
        clearTimeout(this.titleBarHeightTimer);
        this.titleBarHeightTimer = setTimeout(() => {
          if (this.$refs.titleBar) {
            this.titleBar = this.$refs.titleBar.clientHeight;
          } else {
            return this.getTitleBarHeight();
          }
        }, 500);
      }
    }
  },
  computed: {
    ...mapState({
      username: state => state.account.username,
      avatar: state => state.account.avatar,
      isApp: state => state.isApp,
      nativeBarHeight: state => state.nativeBarHeight
    })
  },
  created() {
    document.title = "智即停综合运营 - 首页";
    this.$jsBridge.changeStatusBarStyle("white"); //更改顶部栏颜色
    this.getMsgCount();
    this.onListenGetMsg();
    this.$tools.entering(); //加载页面
    this.getAppVerName();
    this.onUpdate();
  },

  mounted() {
    //实时获取顶部栏高度
    this.getTitleBarHeight();

    //用户点击消息进入应用，直接前往消息页面
    let toMsg = this.$route.params.toMsg;
    if (toMsg) {
      this.$router.push({ name: "Message" });
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }

    if (this.titleBarHeightTimer) {
      clearTimeout(this.titleBarHeightTimer);
    }

    if (this.getMsgTimer) {
      clearInterval(this.getMsgTimer);
    }
  }
};
</script>

<style lang="less" scoped>
.index_wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #edf0f7;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.index_popup {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #fff;

  &.android {
    height: calc(100% - 24px);
    margin-top: 24px;
  }
}

.index_account {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  background-color: #06338e;

  .account_pic {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 16px;
    position: relative;
    margin-bottom: 16px;
    background-color: #efefef;

    .account_message {
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: red;
      font-size: 12px;
      color: #fff;
      padding: 1px 4px;
      border-radius: 50%;
    }
  }

  .account_name {
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #fff;
  }
}

.index_setting {
  flex: 1;

  .settting_item {
    margin: 20px 0;
    display: flex;
    align-items: center;

    .item_icon {
      font-size: 20px;
      margin: 0 16px;
      width: 24px;
      height: 24px;

      &.update {
        width: 28px;
        height: 28px;
        transform: translateX(-2px);
      }

      .icon-message {
        color: #fb6373;
      }

      .icon-update {
        color: #51c566;
      }

      .icon-setting {
        color: #ffd24d;
      }

      .icon-dial {
        color: #04be02;
      }
    }

    .item_title {
      color: #333;
      font-size: 16px;
      margin-right: 4px;
    }

    .item_msg {
      width: 20px;
      height: 20px;
      background-color: red;
      border-radius: 50%;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }

    .item_point {
      width: 6px;
      height: 6px;
      background-color: red;
      border-radius: 50%;
    }
  }
}

.index_logout {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  .logout {
    padding: 6px 14px;
    border: 1px solid rgba(7, 17, 27, 0.1);
    font-size: 16px;
    color: #4c515c;
  }
}

.index_operation {
  width: 100%;
  padding: 12px 20px;
  flex: 1;

  .index_operation_item {
    width: 100%;
    min-height: 120px;
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 14px;
    padding: 12px 18px;
    box-shadow: 2px 2px 4px #999999;
    overflow: hidden;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    .item_title {
      font-size: 18px;
      font-weight: 600;
      margin: 10px 0;
      position: relative;
      z-index: 10;
    }

    .item_content {
      width: 160px;
      font-size: 14px;
      color: #4b5056;
      position: relative;
      z-index: 10;
    }

    .item_mask {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 20;
    }

    .background {
      height: 90px;
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 1;
      // background-color : red ;

      &.red {
        background-color: red;
      }

      &.blue {
        background-color: blue;
      }

      &.green {
        background-color: green;
      }

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}

.index_icon {
  text-align: center;
  width: 70px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;

  .appCode {
    color: #ccc;
    font-size: 16px;
    margin-top: 6px;
  }
}

// .van-popup--left{
//   transform: translate3d(0,0,0);
//   top : 0 ;
// }
.index_popup_box {
  width: 50%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
}

.index_popup_mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(7, 17, 27, 0.8);
}

.pop-enter-to,
.pop-leave {
  transform: translateX(0);
}

.pop-enter,
.pop-leave-to {
  transform: translateX(-100%);
}

.pop-enter-active,
.pop-leave-active {
  transition: all 0.3s;
}

.index-bottom {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 108%;
  padding: 0 4%;
  transform: translateX(-4%);
}

.opeartion-outside {
  margin-bottom: 22px;
}

.partition-title {
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 6px;

  .title-icon {
    color: #238be4;
    margin-right: 6px;
    font-size: 24px;
  }

  .title-text {
    font-size: 18px;
    font-weight: 700;
    color: #333;
  }
}
</style>
